import getAccreditationActive from '@/graphql/accreditations/getAccreditationActive.graphql'

export default {
  apollo: {
    getcurrentaccreditation: {
      query: getAccreditationActive,
      variables() {
        return {
          id: this.$store.state.accreditation.currentAccreditationId
        }
      },
      update(data) {
        if (!data.accreditation.isActive) {
          this.$router.push('/')
        }
      }
    },
  }
}
